// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Translation from "../bindings/Translation.bs.js";

function UnitName0(Props) {
  var unit = Props.unit;
  var t = Translation.useT(/* Units */1);
  return t(unit);
}

var make = UnitName0;

export {
  make ,
  
}
/* Translation Not a pure module */
