// Generated by ReScript, PLEASE EDIT WITH CARE

import * as T from "../T.bs.js";
import * as Data from "../data/Data.bs.js";
import * as CssJs from "bs-css-emotion/src/CssJs.bs.js";
import * as React from "react";
import * as Global from "../Global.bs.js";
import * as TftSet from "../data/TftSet.bs.js";
import * as Tooltip from "../components/Tooltip.bs.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import Memoizee from "memoizee";
import * as ItemImage from "./ItemImage.bs.js";
import * as UnitImage from "./UnitImage.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ScreenAtom from "../atoms/ScreenAtom.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ItemTooltip from "./ItemTooltip.bs.js";
import * as UnitTooltip from "./UnitTooltip.bs.js";

function getItemSize(size) {
  if (size >= 57) {
    return 17;
  } else if (size > 48) {
    return 15;
  } else if (size > 41) {
    return 14;
  } else if (size > 41) {
    return 13;
  } else if (size > 37) {
    return 12;
  } else if (size > 33) {
    return 11;
  } else {
    return 10;
  }
}

var itemsWrap = Memoizee((function (itemSize, s, sizeSm, sizeMd) {
        var styl = [
          CssJs.width(CssJs.px(s + 8 | 0)),
          CssJs.bottom(CssJs.px((-itemSize | 0) / 2 | 0))
        ];
        if (sizeSm !== undefined) {
          styl.push(T.sm_([CssJs.width(CssJs.px(sizeSm + 8 | 0))]));
        }
        if (sizeMd !== undefined) {
          styl.push(T.sm_([CssJs.width(CssJs.px(sizeMd + 8 | 0))]));
        }
        return CssJs.merge([
                    "absolute flex justify-center left-[-4px]",
                    CssJs.style(styl)
                  ]);
      }), {
      primitive: true
    });

var Styles = {
  itemsWrap: itemsWrap
};

function UnitImageExtended$Items(Props) {
  var className = Props.className;
  var unfilteredOpt = Props.unfiltered;
  var items = Props.items;
  var sizeOpt = Props.size;
  var unfiltered = unfilteredOpt !== undefined ? unfilteredOpt : false;
  var size = sizeOpt !== undefined ? sizeOpt : 18;
  var set = TftSet.use(undefined);
  var items$1 = !unfiltered && (items.includes("99") || items.includes("ThiefsGloves")) ? ["ThiefsGloves"] : (
      !unfiltered && (items.includes("2099") || items.includes("5ThiefsGlovesRadiant")) ? ["5ThiefsGlovesRadiant"] : Belt_Array.keep(items, (function (itemId) {
                return Belt_Option.isSome(Data.itemInfo(itemId, set));
              }))
    );
  return React.createElement("div", {
              className: Belt_Option.getWithDefault(className, "") + " flex"
            }, Belt_Array.mapWithIndex(items$1, (function (idx, itemId) {
                    return React.createElement(ItemTooltip.make, {
                                itemId: itemId,
                                children: React.createElement(ItemImage.make, {
                                      itemId: itemId,
                                      size: size,
                                      className: (
                                        idx === 0 ? "" : "border-l-0"
                                      ) + " box-content border border-solid  border-white2"
                                    }),
                                key: String(idx)
                              });
                  })));
}

var Items = {
  make: UnitImageExtended$Items
};

function getBgPath(starLevel) {
  var radius = "6.75";
  var width = String(4 + Math.imul(starLevel - 1 | 0, 11) | 0);
  var height = "1";
  return "M0,0 h" + width + " a" + radius + "," + radius + " 0 0 1 " + radius + "," + radius + " v" + height + " a" + radius + "," + radius + " 0 0 1 -" + radius + "," + radius + " h-" + width + " a" + radius + "," + radius + " 0 0 1 -" + radius + ",-" + radius + " v-" + height + " a" + radius + "," + radius + " 0 0 1 " + radius + ",-" + radius + " z";
}

var bgPaths = [
  getBgPath(1),
  getBgPath(2),
  getBgPath(3),
  getBgPath(4)
];

function getColor(goldenStars, starLevel) {
  if (goldenStars) {
    return "rgba(255, 205, 79, 0.93)";
  } else if (starLevel !== 4) {
    return "var(--white1)";
  } else {
    return "var(--teal-11)";
  }
}

var bgColor = "rgba(96, 125, 139, 0.65)";

function UnitImageExtended$StarLevel(Props) {
  var className = Props.className;
  var starTooltip = Props.starTooltip;
  var goldenStars = Props.goldenStars;
  var starLevel = Props.starLevel;
  var starLevel$1 = starLevel < 4 ? starLevel : 4;
  var color = getColor(goldenStars, starLevel$1);
  var offset = Global.f(3 - starLevel$1 | 0) * 5.5;
  var width = starLevel$1 === 4 ? "56" : "44";
  var left = starLevel$1 === 4 ? "-6" : "-0.5";
  var tmp = {
    height: "17",
    width: width,
    shapeRendering: "auto",
    viewBox: left + " 0 " + width + " 17"
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  var el = React.createElement("svg", tmp, React.createElement("path", {
            d: Belt_Array.getExn(bgPaths, starLevel$1 - 1 | 0),
            fill: bgColor,
            transform: "translate(" + Global.toFixed(undefined, 9 + offset) + ", 1)"
          }), Belt_Array.map(Belt_Array.range(1, starLevel$1), (function (idx) {
              return React.createElement("path", {
                          key: String(idx),
                          d: "M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z",
                          fill: color,
                          transform: "translate(" + Global.toFixed(undefined, Global.f(5 + Math.imul(idx - 1 | 0, 11) | 0) + offset) + ",2)scale(0.5)"
                        });
            })));
  if (starTooltip !== undefined) {
    return React.createElement(Tooltip.make, {
                title: Caml_option.valFromOption(starTooltip),
                children: el
              });
  } else {
    return el;
  }
}

var make = React.memo(UnitImageExtended$StarLevel);

function getBgPath$1(starLevel) {
  var radius = "5.5";
  var width = String(3 + Math.imul(starLevel - 1 | 0, 9) | 0);
  var height = "1";
  return "M0,0 h" + width + " a" + radius + "," + radius + " 0 0 1 " + radius + "," + radius + " v" + height + " a" + radius + "," + radius + " 0 0 1 -" + radius + "," + radius + " h-" + width + " a" + radius + "," + radius + " 0 0 1 -" + radius + ",-" + radius + " v-" + height + " a" + radius + "," + radius + " 0 0 1 " + radius + ",-" + radius + " z";
}

var bgPaths$1 = [
  getBgPath$1(1),
  getBgPath$1(2),
  getBgPath$1(3),
  getBgPath$1(4)
];

function UnitImageExtended$StarLevel$Small(Props) {
  var className = Props.className;
  var starTooltip = Props.starTooltip;
  var goldenStars = Props.goldenStars;
  var starLevel = Props.starLevel;
  var starLevel$1 = starLevel < 4 ? starLevel : 4;
  var color = getColor(goldenStars, starLevel$1);
  var offset = 6 + Global.f(3 - starLevel$1 | 0) * 4.5;
  var width = starLevel$1 === 4 ? "50" : "36";
  var left = starLevel$1 === 4 ? "-6" : "2";
  var tmp = {
    height: "17",
    width: width,
    shapeRendering: "auto",
    viewBox: left + " -1 " + width + " 17"
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  var el = React.createElement("svg", tmp, React.createElement("path", {
            d: Belt_Array.getExn(bgPaths$1, starLevel$1 - 1 | 0),
            fill: bgColor,
            transform: "translate(" + Global.toFixed(undefined, 3.5 + offset) + ", 1)"
          }), Belt_Array.map(Belt_Array.range(1, starLevel$1), (function (idx) {
              return React.createElement("path", {
                          key: String(idx),
                          d: "M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z",
                          fill: color,
                          transform: "translate(" + Global.toFixed(undefined, Global.f(Math.imul(idx - 1 | 0, 9)) + offset) + ",2)scale(0.4)"
                        });
            })));
  if (starTooltip !== undefined) {
    return React.createElement(Tooltip.make, {
                title: Caml_option.valFromOption(starTooltip),
                children: el
              });
  } else {
    return el;
  }
}

var make$1 = React.memo(UnitImageExtended$StarLevel$Small);

var Small = {
  getBgPath: getBgPath$1,
  bgPaths: bgPaths$1,
  make: make$1
};

var StarLevel = {
  getBgPath: getBgPath,
  bgPaths: bgPaths,
  getColor: getColor,
  bgColor: bgColor,
  make: make,
  Small: Small
};

function UnitImageExtended(Props) {
  var className = Props.className;
  var s = Props.size;
  var sizeSm = Props.sizeSm;
  var sizeMd = Props.sizeMd;
  var unitId = Props.unitId;
  var starLevel = Props.starLevel;
  var forceStarLevelOpt = Props.forceStarLevel;
  var goldenStarsOpt = Props.goldenStars;
  var itemSize = Props.itemSize;
  var itemSizeCompact = Props.itemSizeCompact;
  var items = Props.items;
  var starTooltip = Props.starTooltip;
  var unfilteredItems = Props.unfilteredItems;
  var showName = Props.showName;
  var forceStarLevel = forceStarLevelOpt !== undefined ? forceStarLevelOpt : false;
  var goldenStars = goldenStarsOpt !== undefined ? goldenStarsOpt : Caml_obj.caml_equal(starLevel, 3);
  var screenSize = ScreenAtom.use(undefined);
  var baseCn = "relative flex flex-col items-center";
  var currSize = screenSize !== 4 ? (
      screenSize >= 5 ? s : (
          sizeMd !== undefined ? sizeMd : (
              sizeSm !== undefined ? sizeSm : s
            )
        )
    ) : (
      sizeSm !== undefined ? sizeSm : s
    );
  var itemSize$1 = screenSize !== 4 ? (
      screenSize >= 5 ? Belt_Option.getWithDefault(itemSizeCompact, getItemSize(s)) : Belt_Option.getWithDefault(itemSize, getItemSize(sizeMd !== undefined ? sizeMd : (
                    sizeSm !== undefined ? sizeSm : s
                  )))
    ) : Belt_Option.getWithDefault(itemSizeCompact, getItemSize(Belt_Option.getWithDefault(sizeSm, s)));
  var tmp = {
    unitId: unitId,
    size: s,
    nameOffset: items.length !== 0 ? itemSize$1 / 2 | 0 : 0
  };
  if (sizeSm !== undefined) {
    tmp.sizeSm = sizeSm;
  }
  if (sizeMd !== undefined) {
    tmp.sizeMd = sizeMd;
  }
  if (showName !== undefined) {
    tmp.showName = showName;
  }
  var tmp$1;
  if (starLevel !== undefined) {
    var exit = 0;
    if (starLevel !== 1 || forceStarLevel) {
      exit = 1;
    } else {
      tmp$1 = null;
    }
    if (exit === 1) {
      var cn = "mx-auto absolute flex top-[-7px]" + (
        s > 30 ? "" : " scale-75"
      );
      if (currSize < 36) {
        var tmp$2 = {
          className: cn,
          goldenStars: goldenStars,
          starLevel: starLevel
        };
        if (starTooltip !== undefined) {
          tmp$2.starTooltip = Caml_option.valFromOption(starTooltip);
        }
        tmp$1 = React.createElement(make$1, tmp$2);
      } else {
        var tmp$3 = {
          className: cn,
          goldenStars: goldenStars,
          starLevel: starLevel
        };
        if (starTooltip !== undefined) {
          tmp$3.starTooltip = Caml_option.valFromOption(starTooltip);
        }
        tmp$1 = React.createElement(make, tmp$3);
      }
    }
    
  } else {
    tmp$1 = null;
  }
  var tmp$4;
  if (items.length === 0) {
    tmp$4 = null;
  } else {
    var tmp$5 = {
      className: itemsWrap(itemSize$1, s, sizeSm, sizeMd),
      items: items,
      size: itemSize$1
    };
    if (unfilteredItems !== undefined) {
      tmp$5.unfiltered = Caml_option.valFromOption(unfilteredItems);
    }
    tmp$4 = React.createElement(UnitImageExtended$Items, tmp$5);
  }
  return React.createElement("div", {
              className: className !== undefined ? className + " flex-shrink-0 " + baseCn : "relative flex flex-col items-center flex-shrink-0"
            }, React.createElement(UnitTooltip.make, {
                  unit: unitId,
                  children: React.createElement(UnitImage.make, tmp)
                }), tmp$1, tmp$4);
}

var make$2 = UnitImageExtended;

export {
  getItemSize ,
  Styles ,
  Items ,
  StarLevel ,
  make$2 as make,
  
}
/* itemsWrap Not a pure module */
