// Generated by ReScript, PLEASE EDIT WITH CARE

import TraitImageTsx from "./TraitImage.tsx";
import * as TraitImageTsx$1 from "./TraitImage.tsx";

var make = TraitImageTsx$1.PlainIcon;

var PlainIcon = {
  make: make
};

var make$1 = TraitImageTsx$1.PlainIcon2;

var PlainIcon2 = {
  make: make$1
};

var make$2 = TraitImageTsx$1.Inner;

var Inner = {
  make: make$2
};

var make$3 = TraitImageTsx;

export {
  PlainIcon ,
  PlainIcon2 ,
  Inner ,
  make$3 as make,
  
}
/* make Not a pure module */
