// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Data from "../data/Data.bs.js";
import * as React from "react";
import * as TftSet from "../data/TftSet.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Tooltip from "../components/Tooltip.bs.js";
import Memoizee from "memoizee";
import * as ItemImage from "./ItemImage.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Translation from "../bindings/Translation.bs.js";
import * as RitoDescription from "../components/RitoDescription.bs.js";
import * as RitoDescription2 from "../components/RitoDescription2.bs.js";

function getBaseStatsStr(item, set) {
  var parts = [];
  var effects = item.effects;
  var ad = Js_dict.get(effects, "AD");
  if (ad !== undefined) {
    if (set >= 80 || set < 30) {
      parts.push([
            "+" + String(ad * 100 | 0) + "%",
            "Attack Damage"
          ]);
    } else {
      parts.push([
            "+" + String(ad | 0),
            "Attack Damage"
          ]);
    }
  }
  var ias = Js_dict.get(effects, "AS");
  if (ias !== undefined) {
    parts.push([
          "+" + String(ias | 0) + "%",
          "Attack Speed"
        ]);
  }
  var ias$1 = Js_dict.get(effects, "AP");
  if (ias$1 !== undefined) {
    parts.push([
          "+" + String(ias$1 | 0),
          "Ability Power"
        ]);
  }
  var ias$2 = Js_dict.get(effects, "Mana");
  if (ias$2 !== undefined) {
    parts.push([
          "+" + String(ias$2 | 0),
          "Mana"
        ]);
  }
  var ias$3 = Js_dict.get(effects, "Armor");
  if (ias$3 !== undefined) {
    parts.push([
          "+" + String(ias$3 | 0),
          "Armor"
        ]);
  }
  var ias$4 = Js_dict.get(effects, "MagicResist");
  if (ias$4 !== undefined) {
    parts.push([
          "+" + String(ias$4 | 0),
          "Magic Resist"
        ]);
  }
  var ias$5 = Js_dict.get(effects, "Health");
  if (ias$5 !== undefined) {
    parts.push([
          "+" + String(ias$5 | 0),
          "Health"
        ]);
  }
  var ias$6 = Js_dict.get(effects, "CritChance");
  if (ias$6 !== undefined) {
    parts.push([
          "+" + String(ias$6 | 0) + "%",
          "Critical Strike Chance"
        ]);
  }
  var ias$7 = Js_dict.get(effects, "BonusDamage");
  if (ias$7 !== undefined) {
    parts.push([
          "+" + String(ias$7 * 100 | 0) + "%",
          "Damage Amp"
        ]);
  }
  var ias$8 = Js_dict.get(effects, "StatOmnivamp");
  if (ias$8 !== undefined) {
    parts.push([
          "+" + String(ias$8 * 100 | 0) + "%",
          "Omnivamp"
        ]);
  }
  return parts;
}

function ItemTooltip$BaseStats(Props) {
  var item = Props.item;
  var t = Translation.useT(/* Common */0);
  var set = TftSet.use(undefined);
  var lang = Translation.useLocale();
  var textFirst = lang === "ko" || lang === "ja";
  return React.createElement("div", {
              className: "flex flex-col text-xs text-white2 pb-2"
            }, Belt_Array.map(getBaseStatsStr(item, set), (function (param) {
                    var label = param[1];
                    var tmp;
                    switch (label) {
                      case "Ability Power" :
                          tmp = React.createElement("img", {
                                height: "12",
                                src: "https://cdn.tft.tools/general/ap.png",
                                width: "12"
                              });
                          break;
                      case "Armor" :
                          tmp = React.createElement("img", {
                                height: "12",
                                src: "https://cdn.tft.tools/general/armor.png",
                                width: "12"
                              });
                          break;
                      case "Attack Damage" :
                          tmp = React.createElement("img", {
                                height: "12",
                                src: "https://cdn.tft.tools/general/ad.png",
                                width: "12"
                              });
                          break;
                      case "Attack Speed" :
                          tmp = React.createElement("img", {
                                height: "12",
                                src: "https://cdn.tft.tools/general/as.png",
                                width: "12"
                              });
                          break;
                      case "Critical Strike Chance" :
                          tmp = React.createElement("img", {
                                height: "12",
                                src: "https://cdn.tft.tools/general/crit_chance.png",
                                width: "12"
                              });
                          break;
                      case "Damage Amp" :
                          tmp = React.createElement("img", {
                                height: "12",
                                src: "https://ap.tft.tools/img/general/da.png?w=12",
                                width: "12"
                              });
                          break;
                      case "Dodge Chance" :
                          tmp = React.createElement("img", {
                                height: "12",
                                src: "https://cdn.tft.tools/general/dodge.png",
                                width: "12"
                              });
                          break;
                      case "Health" :
                          tmp = React.createElement("img", {
                                height: "12",
                                src: "https://cdn.tft.tools/general/hp.png",
                                width: "12"
                              });
                          break;
                      case "Magic Resist" :
                          tmp = React.createElement("img", {
                                height: "12",
                                src: "https://cdn.tft.tools/general/mr.png",
                                width: "12"
                              });
                          break;
                      case "Mana" :
                          tmp = React.createElement("img", {
                                height: "12",
                                src: "https://cdn.tft.tools/general/mana.png",
                                width: "12"
                              });
                          break;
                      case "Omnivamp" :
                          tmp = React.createElement("img", {
                                height: "12",
                                src: "https://ap.tft.tools/img/general/sv.png?w=12",
                                width: "12"
                              });
                          break;
                      default:
                        tmp = null;
                    }
                    return React.createElement("div", {
                                key: label,
                                className: "flex items-center pr-2"
                              }, textFirst ? React.createElement("div", {
                                      className: "pr-1"
                                    }, t(label)) : null, React.createElement("div", {
                                    className: "flex-shrink-0 mr-[3px] h-[12px]"
                                  }, tmp), React.createElement("div", undefined, param[0]), textFirst ? null : React.createElement("div", {
                                      className: "pl-1"
                                    }, t(label)));
                  })));
}

var BaseStats = {
  make: ItemTooltip$BaseStats
};

function getContents(itemId) {
  var t = Translation.useT(/* Items */3);
  React.useContext(TftSet.ctx);
  var setNum = TftSet.use(undefined);
  var desc = t(itemId + "_desc");
  var name = t(itemId);
  var item = Data.itemInfo(itemId, setNum);
  if (item === undefined) {
    return React.createElement("div", undefined, "???");
  }
  var item$1 = Caml_option.valFromOption(item);
  var match = item$1.from;
  var tmp;
  if (match.length !== 2) {
    tmp = null;
  } else {
    var f1 = match[0];
    var f2 = match[1];
    var items = Data.itemsArr(setNum);
    var id1 = Belt_Array.getExn(items, f1 - 1 | 0)[0];
    var id2 = Belt_Array.getExn(items, f2 - 1 | 0)[0];
    tmp = React.createElement("div", {
          className: "ml-auto flex leading-[20px] h-[20px]"
        }, React.createElement(ItemImage.make, {
              itemId: id1,
              size: 20
            }), React.createElement("div", {
              className: "px-1 text-[16px]"
            }, "+"), React.createElement(ItemImage.make, {
              itemId: id2,
              size: 20
            }));
  }
  var tmp$1;
  if (setNum === 10 || setNum >= 90) {
    tmp$1 = React.createElement(RitoDescription2.make, {
          desc: desc
        });
  } else {
    var replacedText = RitoDescription.replaceItemVariables(desc, item$1);
    tmp$1 = React.createElement(RitoDescription.make, {
          parts: RitoDescription.parse(replacedText)
        });
  }
  return React.createElement("div", {
              className: "text-sm"
            }, React.createElement("div", {
                  className: "flex font-medium pb-2"
                }, React.createElement("div", {
                      className: "whitespace-nowrap font-montserrat font-semibold text-base pr-3"
                    }, name), tmp), React.createElement(ItemTooltip$BaseStats, {
                  item: item$1
                }), tmp$1);
}

var getContents$1 = Memoizee(getContents, {
      primitive: true
    });

function ItemTooltip(Props) {
  var itemId = Props.itemId;
  var children = Props.children;
  var disabled = Props.disabled;
  var tmp = {
    title: getContents$1(itemId),
    children: children
  };
  if (disabled !== undefined) {
    tmp.disabled = Caml_option.valFromOption(disabled);
  }
  return React.createElement(Tooltip.make, tmp);
}

var make = ItemTooltip;

export {
  getBaseStatsStr ,
  BaseStats ,
  getContents$1 as getContents,
  make ,
  
}
/* getContents Not a pure module */
