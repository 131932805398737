// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Sd from "./Sd.bs.js";
import * as Data from "../data/Data.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as TftSet from "../data/TftSet.bs.js";
import * as CdnAtom from "../atoms/CdnAtom.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Tooltip from "../components/Tooltip.bs.js";
import Memoizee from "memoizee";
import * as UnitName0 from "./UnitName0.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as TraitImage from "./TraitImage.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Translation from "../bindings/Translation.bs.js";
import * as RitoDescription2 from "../components/RitoDescription2.bs.js";

function UnitTooltip$AbilityDetailsRaw(Props) {
  var details = Props.details;
  if (details !== undefined && details.length !== 0) {
    return React.createElement(React.Fragment, undefined, React.createElement("hr", {
                    className: "my-[10px] mx-1 border-white2"
                  }), React.createElement("div", {
                    className: "flex flex-col gap-[2px]"
                  }, Belt_Array.mapWithIndex(details, (function (idx, param) {
                          var vs = param[1];
                          return React.createElement("div", {
                                      key: String(idx),
                                      className: "flex flex-wrap justify-between"
                                    }, React.createElement(RitoDescription2.make, {
                                          desc: param[0]
                                        }), React.createElement("div", {
                                          className: "ml-auto"
                                        }, (vs == null) ? null : React.createElement("span", {
                                                className: "text-white2"
                                              }, Belt_Array.mapWithIndex(vs, (function (idx, v) {
                                                      return React.createElement(React.Fragment, {
                                                                  children: null,
                                                                  key: String(idx)
                                                                }, idx === 0 ? "[" : "/", idx === 1 ? React.createElement("span", {
                                                                        className: "text-white1"
                                                                      }, v) : v);
                                                    })), "]")));
                        }))));
  } else {
    return null;
  }
}

var AbilityDetailsRaw = {
  make: UnitTooltip$AbilityDetailsRaw
};

function UnitTooltip$AbilityDetails(Props) {
  var unitId = Props.unitId;
  var info = Props.info;
  var unitsT = Translation.useT(/* Units */1);
  var details = Belt_Option.map(info.ability.details, (function (vs) {
          return Belt_Array.mapWithIndex(vs, (function (idx, vs) {
                        return [
                                unitsT(unitId + "_details_" + String(idx)),
                                vs
                              ];
                      }));
        }));
  return React.createElement(UnitTooltip$AbilityDetailsRaw, {
              details: details
            });
}

var AbilityDetails = {
  make: UnitTooltip$AbilityDetails
};

function UnitTooltip$Contents(Props) {
  var unit = Props.unit;
  React.useContext(TftSet.ctx);
  var setNum = TftSet.use(undefined);
  var basePath = CdnAtom.usePath(undefined);
  var basePath3 = CdnAtom.usePath4(undefined);
  var traitsT = Translation.useT(/* Traits */2);
  var traits = Sd.getUnitTraits(unit, setNum);
  var commonT = Translation.useT(/* Common */0);
  var t = Translation.useT(/* Units */1);
  var info = Js_dict.get(Data.units(setNum), unit);
  var tmp;
  if (info !== undefined) {
    var info$1 = Caml_option.valFromOption(info);
    tmp = React.createElement(React.Fragment, undefined, React.createElement("div", {
              className: "bg-bg2 rounded-lg px-2 py-1 font-montserrat font-medium self-start"
            }, commonT(Belt_Option.getWithDefault(info$1.itemization, ""))), React.createElement("div", {
              className: "flex items-center gap-[2px] pr-2"
            }, React.createElement("div", {
                  className: "flex-shrink-0"
                }, React.createElement("img", {
                      title: "Range",
                      alt: "Range",
                      height: "13",
                      src: basePath + "/general/range.png",
                      width: "13"
                    })), React.createElement("div", {
                  className: "pl-1 font-montserrat text-base font-medium text-white1 break-all"
                }, String(info$1.stats.range))));
  } else {
    tmp = null;
  }
  var tmp$1;
  if (info !== undefined) {
    var info$2 = Caml_option.valFromOption(info);
    var mana = info$2.stats.mana;
    var manaCost = mana !== 0 ? [
        info$2.stats.initialMana,
        mana
      ] : undefined;
    var desci = t(unit + "_desc");
    var tmp$2;
    try {
      tmp$2 = React.createElement("div", {
            className: "pt-2"
          }, React.createElement(RitoDescription2.make, {
                desc: desci
              }), React.createElement(UnitTooltip$AbilityDetails, {
                unitId: unit,
                info: info$2
              }));
    }
    catch (exn){
      tmp$2 = null;
    }
    tmp$1 = React.createElement("div", {
          className: "pt-3"
        }, React.createElement("div", {
              className: "flex items-center"
            }, React.createElement("img", {
                  className: "rounded flex-shrink-0 " + (
                    Belt_Option.isSome(manaCost) ? "w-8 h-8" : "w-6 h-6"
                  ),
                  height: "32",
                  src: basePath3 + "/ability/" + unit + ".jpg?w=32",
                  width: "32"
                }), React.createElement("div", {
                  className: "pl-2 leading-none"
                }, React.createElement("div", {
                      className: "font-medium text-sm"
                    }, t(unit + "_ability")), manaCost !== undefined ? React.createElement("div", {
                        className: "flex pt-[2px] h-[18px]"
                      }, React.createElement("div", {
                            className: "mt-[-1px] mr-[2px] w-[14px] h-[14px]"
                          }, React.createElement("img", {
                                alt: "Mana",
                                height: "14",
                                src: basePath + "/general/mana.png",
                                width: "14"
                              })), React.createElement("div", {
                            className: "pt-[1px]"
                          }, String(manaCost[0]) + "/" + String(manaCost[1]))) : null)), tmp$2);
  } else {
    tmp$1 = null;
  }
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "flex justify-between"
                }, React.createElement("div", undefined, React.createElement("div", {
                          className: "font-montserrat font-medium text-lg pb-2"
                        }, React.createElement(UnitName0.make, {
                              unit: unit
                            })), React.createElement("div", {
                          className: "flex flex-col gap-1"
                        }, Belt_Array.map(traits, (function (trait) {
                                return React.createElement("div", {
                                            key: trait,
                                            className: "flex items-center"
                                          }, React.createElement(TraitImage.PlainIcon.make, {
                                                className: "mx-[0px]",
                                                traitId: trait,
                                                size: 24
                                              }), React.createElement("div", {
                                                className: "text-base pl-2"
                                              }, traitsT(trait)));
                              })))), React.createElement("div", {
                      className: "flex flex-col gap-[9px] items-end"
                    }, tmp)), tmp$1);
}

var Contents = {
  make: UnitTooltip$Contents
};

var getContents = Memoizee(function (unit) {
      return React.createElement(UnitTooltip$Contents, {
                  unit: unit
                });
    });

function UnitTooltip(Props) {
  var disabled = Props.disabled;
  var unit = Props.unit;
  var children = Props.children;
  var unit$1 = unit === "tft9_reksai" ? "TFT9_RekSai" : unit;
  var contents = Curry._1(getContents, unit$1);
  var tmp = {
    title: contents,
    children: children
  };
  if (disabled !== undefined) {
    tmp.disabled = Caml_option.valFromOption(disabled);
  }
  return React.createElement(Tooltip.make, tmp);
}

var make = UnitTooltip;

export {
  AbilityDetailsRaw ,
  AbilityDetails ,
  Contents ,
  getContents ,
  make ,
  
}
/* getContents Not a pure module */
