// Generated by ReScript, PLEASE EDIT WITH CARE

import ItemImageTsx from "./ItemImage.tsx";

var make = ItemImageTsx;

export {
  make ,
  
}
/* make Not a pure module */
