// Generated by ReScript, PLEASE EDIT WITH CARE

import UnitImageTsx from "./UnitImage.tsx";

var make = UnitImageTsx;

export {
  make ,
  
}
/* make Not a pure module */
